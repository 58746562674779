body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: Arial, sans-serif;
}

/*Inicio Navbar Styles*/

header {
	color: #f8f9fa;
	padding: 0;
	background-color: #0a2e52;
	height: 40px !important;
	display: flex;
	align-items: center;
}

header svg {
	font-size: 30px;
	margin-left: 20px;
	margin-top: 5px;
	cursor: pointer;
}

.sidenav {
	width: 270px;
	background-color: #0a2e52;
	position: absolute;
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
	height: 95vh;
	padding: 0 10px;
	left: -100%;
	transition: all 0.5s;
}

.sidenav.active{
	left: 0;
}


.logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo {
	margin-top: 30px;
	margin-bottom: 30px;
	max-Width: 70%;
	height: auto,
}

ul {
	padding: 0;
}
ul li {
	list-style: none;
	margin: 10px 0;
}

.nav-link {
	color: #f8f9fa;
	text-decoration: none;
	font-size: 16px;
	display: block;
	padding: 10px 15px;
	border-radius: 6px;
}

.nav-link:hover,
.nav-link.active {
	background: #172b4d;
}

.main {
	height: 90vh;
	width: calc(100% - 270px); /* Ajustamos para ocupar el espacio correcto */
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 270px; /* Alineamos el contenido principal con el navbar */
	transition: width 0.5s ease, margin-left 0.5s ease;
}

.main.full-width {
	width: 100%; /* Espacio que ocupa cuando la barra está oculta */
	margin-left: 0;
}

.logout-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 1rem;
	margin-top: 150px;
	font-size: 1rem;
	font-weight: bold;
	border-radius: 0.25rem; /* Bordes redondeados */
	text-decoration: none; /* Quita el subrayado */
	transition: background-color 0.2s, color 0.2s; /* Transiciones suaves */
}


/*Fin Navbar Styles*/

.margin-tb {
	margin-top: 80px;
	margin-bottom: 20px;
}

.pull-left {
	float: left;
}

.table {
	width: 100%;
	margin-bottom: 50px;
	color: #212529;
}

.thead-dark th {
	color: #fff;
	background-color: #343a40;
	border-color: #454d55;
}

.btn-group .btn {
	margin-right: 5px;
}

.breadcrumb {
	background-color: #f8f9fa;
	padding: 0.75rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	border-radius: 0.375rem;
}

.breadcrumb-item + .breadcrumb-item::before {
	content: ">";
}
/*
.nav-pills .nav-link {
	margin-bottom: 5px;
}

.table {
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
}

.thead-dark th {
	color: #fff;
	background-color: #343a40;
	border-color: #454d55;
}

.btn-group .btn {
	margin-right: 5px;
}

.nav-link {
	display: flex;
	align-items: center;
}

.nav-link .material-icons {
	font-size: 1.2rem;
	vertical-align: middle;
	margin-right: 8px;
}

.sidebar {
	display: flex;
	flex-direction: column;
	width: 240px;
	background-color: #c6d9ec;
	padding: 15px;
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	height: 100vh;
}

.sidebar .nav-link {
	color: #030303;
	margin-bottom: 10px;
	padding: 10px 15px; 
	border-radius: 4px;
}

.sidebar .nav-link.active {
	background-color: #6fadeb;
}

.sidebar .nav-link:hover {
	background-color: #007bff;
}

.bottom-nav {
	display: none;
}

.menuMovil {
	display: none;
}

.nav-container {
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.nav-logo h1 {
	margin: 0;
	font-size: 1.5em;
}

.nav-actions button:hover {
	background-color: #d32f2f;
}*/

.containerLogin {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 100vh;
	background-color: #f0f0f0;
	background-image: url("/public/preoperacional.jpg");
	background-size: cover;
	background-position: center;
}

.formLogin {
	padding: 2rem;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
	width: 400px;
	margin-right: 150px;
}

.titleLogin {
	margin-bottom: 1rem;
}

.inputGroupLogin {
	margin-bottom: 1rem;
}

.labelLogin {
	display: block;
	margin-bottom: .5rem;
}

.inputLogin {
	width: 100%;
	padding: .5rem;
	border-radius: 5px;
	border: 1px solid #ccc;
}

.buttonLogin {
	width: 100%;
	padding: .75rem;
	border-radius: 5px;
	border: none;
	background-color: #007BFF;
	color: #fff;
	cursor: pointer;
	font-size: 1rem;
}

.loading-container {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}
.spinner {
	border: 8px solid #f3f3f3;
	border-top: 8px solid #3498db;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 1s linear infinite;
}
@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loading-message {
	margin-top: 20px;
	font-size: 1.2rem;
	color: #333;
}
.header-container {
	background-color: #fff;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
	color: white;
	position: fixed;
	text-align: center;
	top: 0;
	width: 100%;
	z-index: 100;
}
.header-image {
	height: auto;
	max-height: 150px;
	object-fit: cover;
	width: 100%;
}
.header-title {
	color: white;
	font-size: 2rem;
	font-weight: bold;
	left: 50%;
	position: absolute;
	text-shadow: 2px 2px 4px rgba(0,0,0,1);
	top: 50%;
	transform: translate(-50%, -50%);
}
.content-container {
	margin-top: 160px;
	padding-bottom: 100px;
}
.question-container {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
}
.question-text {
	flex: 70%;
	margin-right: 10px;
}
.button-group {
	display: flex;
	flex-shrink: 0;
	flex: 30%;
}
.button-group .btn {
	font-size: 0.8rem;
	margin-left: 5px;
}

.btn-custom-success {
	background-color: #f57128 !important;
	border-color: #f57128 !important;
	color: #fff !important;
}
.btn-custom-success:hover{
	background-color: #ee681f !important;
	border-color: #ee681f !important;
}
.btn-custom-outline-primary {
	background-color: transparent !important;
	border-color: #007bff !important;
	color: #007bff !important;
}
.btn-custom-outline-primary:hover{
	background-color: #007bff !important;
	color: #fff !important;
}
.custom-submit-button {
	background-color: #2481c4 !important;
	border-color: #2481c4 !important;
	color: #fff !important;
}

.custom-submit-button:hover {
	background-color: #f57128 !important;
	border-color: #f57128 !important;
	color: #fff !important;
}

.pagination-indicators {
	display: flex;
	justify-content: center;
}
.pagination-indicators .dot {
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
	height: 10px;
	margin: 0 5px;
	width: 10px;
}
.pagination-indicators .active {
	background-color: #717171;
}
.footer-container {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #f8f9fa;
	padding: 10px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
	z-index: 1000;
}
.footer-logo img {
	max-width: 150px;
	height: auto;
	margin-bottom: 10px;
}
.footer-copyright {
	font-size: 0.875rem;
	color: #6c757d;
	text-align: center;
}
#observaciones{
	height: 100px;
}

@media (max-width: 577px) {
	.pagination-buttons .btn {
		width: 48%;
	}
	.submit-button .btn {
		width: 100%;
	}
	.containerLogin {
		justify-content: center;	
	}
	.formLogin {
		width: 80%;
		margin-right: 0;
	}
	.sidebar {
		display: none;
	}

	.btn-group {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 10px;
	}

	.menu {
		display: none;
	}

	.menuMovil {
		display: flex;
	}

	.bottom-nav {
		display: flex;
		justify-content: space-around;
		position: fixed;
		bottom: 0;
		width: 100%;
		background: #fff;
		box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);  
	}

	.bottom-nav .nav-link {
		flex: 1;
		text-align: center;
		padding: 10px 0;
	}
}

.card-custom-bg {
	background-color: #CBEBFA;
}

.card-custom-bg .list-group-item {
	background-color: inherit;
}

.load {
	margin-top: 300px;
}
